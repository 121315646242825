import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  const username = localStorage.getItem("username_mwerezi");
  const [hidden, setHidden] = useState({ login: true, logout: true });

  const logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("id_mwerezi");
    localStorage.removeItem("email_mwerezi");
    localStorage.removeItem("username_mwerezi");
    localStorage.removeItem("phone_mwerezi");
    setHidden({ login: false, logout: true });
    document.getElementById("welcome").innerHTML = "";
  };

  useEffect(() => {
    if (username) {
      setHidden({ login: true, logout: false });
      document.getElementById("welcome").innerHTML = "Welcome " + username;
    } else {
      setHidden({ login: false, logout: true });
    }
  }, []);

  // useEffect(() => {
  //   var speed = 150;
  //   var speed2 = 40;
  //   var str = document.getElementById("str");
  //   var i = 0;
  //   var isRemoving = false;

  //   var messages = [
  //     "Interactive website designs...",
  //     "Data driven mobile applications...",
  //     "Client satisfaction is our goal...",
  //     "Multiple web and mobile designs to choose from...",
  //     "affordable and competitive packages...",
  //   ];

  //   function action() {
  //     if (isRemoving) {
  //       if (str.innerText.length > 0) {
  //         str.innerText = str.innerText.substr(0, str.innerHTML.length - 1);
  //         setTimeout(action, speed2);
  //         return;
  //       }
  //       isRemoving = false;
  //       i++;
  //       if (i >= messages.length) {
  //         i = 0;
  //       }
  //       setTimeout(action, speed);
  //       return;
  //     }
  //     var message = messages[i];
  //     str.innerText = message.substr(0, str.innerHTML.length + 1);
  //     if (str.innerText.length === message.length) {
  //       isRemoving = true;
  //     }
  //     setTimeout(action, isRemoving ? speed2 : speed);
  //   }

  //   setTimeout(action, speed);
  // }, []);

  return (
    <div className="me-0 mb-5">
      <div className="bg-success">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="hero-content">
                <div className="hero-text">
                  <p className="h3 text-white">We are</p>
                  <p className="text-white h2">MWEREZI INTERNATIONAL</p>
                  <p className="text-white">
                    The leading expert in technology business solutions.
                  </p>
                </div>
                <div className="hero-btn">
                  <button
                    className="btn btn-secondary text-warning fw-bold me-2"
                    onClick={() => history.push("/web-packages")}
                  >
                    <i class="fas fa-handshake"></i> Hire Us
                  </button>
                  <button
                    className="btn btn-secondary text-warning fw-bold me-2"
                    onClick={() => history.push("/contactus")}
                  >
                   <i class="fas fa-phone-alt"></i> Contact Us
                  </button>
                  <button
                    hidden={hidden.login}
                    className="btn btn-secondary text-warning fw-bolder"
                    onClick={() => history.push("/login")}
                  >
                    <i class="fa fa-thin fa-user"></i> Login
                  </button>
                  <button
                    hidden={hidden.logout}
                    className="btn btn-warning text-warning fw-bolder"
                    onClick={logOut}
                  >
                    <i class="fa fa-thin fa-user"></i> Logout
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <div className="float-end">
                <img
                  src="imgs/pointing.png"
                  alt="Hero Image"
                  height="200px"
                  style={{ pointerEvents: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <img
          src="imgs/home.png"
          className="d-block w-100"
          height="400"
          alt="..."
          style={{ pointerEvents: "none" }}
        />
      </div> */}
      <div className="row m-2 justify-content-center">
        {/* <p className="text-center fw-bolder h3" style={{color:"brown"}}>We have a wide variety of systems such as POS systems, pharmacy systems, insurance systems, E-commerce sytems, mobile applicatons, Hospital management systems, and many more...</p> */}
        <div className="col-md-6 text-center">
          {/* <img src="/imgs/pos_header.png" alt="" /> */}
          <p className="h1 text-success text-center mb-4" style={{fontFamily:"Brush Script MT', cursive"}}> Point Of Sale system</p>
          <p className="text text-center" style={{height:"100px"}}>We have a state of the art point of sale system that caters for all types of businesses. If your goal is to have a fast and easy to use system with reporting capability and other features such as mpesa integrations, then our system is the best for your business.</p>
          <video
            className="col-md-12"
            id="myVideo"
            autoPlay
            muted
            loop
            >
            <source src="/videos/mpos.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="col-md-6 text-center">
        {/* <img src="/imgs/pharmacy_header.png" alt="" /> */}
        <p className="h1 text-success text-center mb-4" style={{fontFamily:"Brush Script MT', cursive"}}> Pharmacy Management System</p>
          <p className="text text-center" style={{height:"100px"}}>Our pharmacy system is tailor made to handle all pharmacy transactions and also include patient diagnosis and prescription functionalities as well as reporting capabilities.</p>
          <video
            className="col-md-12"
            id="myVideo"
            autoPlay
            muted
            loop
          >
            <source src="/videos/mpharma.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {/* <button onclick="playVideo()">Play</button>
        <button onclick="pauseVideo()">Pause</button> */}
      </div>
      {/* <p className="col-md-12 text-center text-success fw-bold h2" id="str"></p> */}
    </div>
  );
};

export default Home;

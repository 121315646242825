import React, { useState, useEffect } from "react";
import { postData } from "../components/helpers/Data";
import Modal from "../components/helpers/Modal";

const MobilePackages = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const email = localStorage.getItem("email_mwerezi");

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const orderPackage = (package_name) => {
    if (email) {
      const frmData = new FormData();
      frmData.append("package", package_name);
      frmData.append("type", "mobile");
      frmData.append("id", localStorage.getItem("id_mwerezi"));

      postData(frmData, "order_packages")
        .then((data) => {
          if (data[0].includes("Error")) {
            setResponse("Error ordering bronze package ");
          } else {
            setResponse(data);
          }
          setIsModalOpen(true);
        })
        .catch((error) => {
          setResponse("Error ordering bronze package!");
          setIsModalOpen(true);
        });
    } else {
      setResponse("Login first to order!");
      setIsModalOpen(true);
    }
  };

  return (
    <div
      className="mb-5 d-grid gap-3 text-center"
      style={{ marginTop: "3px" }}
      id="packageDiv"
    >
      <div className="row p-2 ">
        <h3
          style={{
            fontSize: "40px",
            color: "purple",
            fontWeight: "bolder",
          }}
        >
          Mobile Application
        </h3>
        <hr />
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          They say CHEAP IS EXPENSIVE. The truth is cheap is always expensive.
          With cheap the quality of a product is always lowered. Our Mobile
          application packages are not CHEAP, they are AFFORDABLE! Whether you
          are interested in the lowest or highest package, we strive to maintain
          the highest quality as we make them pocket friendly. We offer multiple
          mobile application designs to catter for your needs. Find yours today!
        </p>

        <div className="col-md-12">
          <div className="row justify-content-center">
            <div className="col-md-3 mb-5">
              <div
                className="card px-0"
                style={{
                  boxShadow: "5px 10px 8px #888888",
                }}
              >
                <div className="card-header bg-dark text-warning fw-bolder h1">
                  Bronze
                  <p className="h5">35,999 Kshs</p>
                </div>
                <div className="card-body">
                  <p>Best for small businesses</p>
                  <hr />
                  <p>Beautiful responsive mobile app</p>
                  <hr />
                  <p>Partial data driven</p>
                  <hr />
                  <p>1 to 10 screens</p>
                  <hr />
                  <p>Hosting app in playstore</p>
                  <hr />
                  <button
                    class="btn btn-success"
                    onClick={() => orderPackage("bronze")}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div
                className="card px-0"
                style={{
                  boxShadow: "5px 10px 8px #888888",
                }}
              >
                <div className="card-header bg-dark text-danger fw-bolder h1">
                  Silver
                  <p className="h5">55,999 Kshs</p>
                </div>
                <div className="card-body">
                  <p>Best for medium businesses</p>
                  <hr />
                  <p>Beautiful responsive mobile app</p>
                  <hr />
                  <p>fully data driven</p>
                  <hr />
                  <p>11 to 30 screens</p>
                  <hr />
                  <p>Hosting app in playstore</p>
                  <hr />
                  <p>Social media integration (Facebook, Twitter, Instagram)</p>
                  <hr />
                  <button
                    class="btn btn-success"
                    onClick={() => orderPackage("silver")}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div
                className="card px-0"
                style={{
                  boxShadow: "5px 10px 8px #888888",
                }}
              >
                <div className="card-header bg-dark text-info fw-bolder h1">
                  Gold
                  <p className="h5">80,999 Kshs</p>
                </div>
                <div className="card-body">
                  <p>Best for medium and large businesses</p>
                  <hr />
                  <p>Beautiful responsive mobile app</p>
                  <hr />
                  <p>heavily data driven</p>
                  <hr />
                  <p>31 to 50 screens</p>
                  <hr />
                  <p>Hosting app in playstore</p>
                  <hr />
                  <p>
                    Social media integration (Facebook, Twitter, Instagram,
                    Whatsapp)
                  </p>
                  <hr />
                  <p>Payment integration (mpesa)</p>
                  <hr />
                  <button
                    class="btn btn-success"
                    onClick={() => orderPackage("gold")}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div
                className="card px-0"
                style={{
                  boxShadow: "5px 10px 8px #888888",
                }}
              >
                <div
                  className="card-header bg-dark fw-bolder h1"
                  style={{ color: "#2ECC71" }}
                >
                  Platinum
                  <p className="h5">Contact us for price</p>
                </div>
                <div className="card-body">
                  <p>Best for medium and large businesses</p>
                  <hr />
                  <p>Beautiful responsive mobile app</p>
                  <hr />
                  <p>heavily data driven</p>
                  <hr />
                  <p>51 and above screens</p>
                  <hr />
                  <p>Hosting app in playstore</p>
                  <hr />
                  <p>
                    Social media integration (Facebook, Twitter, Instagram,
                    Whatsapp)
                  </p>
                  <hr />
                  <p>Payment Integration (Mpesa, Paypal, bank cards)</p>
                  <hr />
                  <p>Other integrations (Maps, Analytics, geolocations)</p>
                  <hr />
                  <button
                    class="btn btn-success"
                    onClick={() => orderPackage("platinum")}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalIsOpen={isModalOpen}
        closeModal={closeModal}
        body={<p className="text-white fw-bolder">{response}</p>}
        button={
          <p>
            <button onClick={closeModal}></button>
          </p>
        }
      />
    </div>
  );
};

export default MobilePackages;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageRoutes from "./components/helpers/PageRoutes";
import { userDetails } from "./components/helpers/UserDetails";
import { getData } from "./components/helpers/Data";

function App() {
  const [visitors, setVisitors] = useState(0);
  const [secs, setSecs] = useState(0);



  useEffect(() => {
    //save access log
    userDetails();
  }, []);

  // useEffect(() => {
  //   //check access logs at startup
  //   getData("fetch_access_logs")
  //     .then((data) => {
  //       setVisitors(data);
  //     })
  //     .catch();
  //   userDetails();
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setVisitors("checking...");
  //     getData("fetch_access_logs")
  //       .then((data) => {
  //         setVisitors(data);
  //         setSecs(new Date().getSeconds());
  //       })
  //       .catch();
  //   }, 60000);
  // }, [secs]);

  return (
    <div>
      {/* <div className="header bg-white">
        <img src="/imgs/logo.png" alt="" style={{ pointerEvents: "none" }} />
        <div className="header-right">
          <Link to="/">Home</Link>
          <Link to="/web-packages">Website Packages</Link>
          <Link to="/mobile-packages">Mobile Packages</Link>
          <Link to="/portfolio">Portfolio</Link>
          <Link to="/aboutus">About Us</Link>
          <Link to="/contactus">Contact Us</Link>
        </div>
        <h5 className="text-info fw-bold">
          Total visits <span className="text-danger fw-bolder">{visitors}</span>
        </h5>
        <p id="welcome"></p>
      </div> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img
              src="/imgs/logo.png"
              alt=""
              style={{ pointerEvents: "none" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <Link to="/" className="nav-link active" aria-current="page">
                Home
              </Link>
              <Link
                to="/web-packages"
                className="nav-link active"
                aria-current="page"
              >
                Website Packages
              </Link>
              <Link
                to="/mobile-packages"
                className="nav-link active"
                aria-current="page"
              >
                Mobile Packages
              </Link>
              <Link
                to="/portfolio"
                className="nav-link active"
                aria-current="page"
              >
                Portfolio
              </Link>
              <Link
                to="/aboutus"
                className="nav-link active"
                aria-current="page"
              >
                About Us
              </Link>
              <Link
                to="/profile"
                className="nav-link active"
                aria-current="page"
              >
                Profile
              </Link>
              <Link
                to="/orders"
                className="nav-link active"
                aria-current="page"
              >
                Orders
              </Link>
              <Link
                to="/contactus"
                className="nav-link active"
                aria-current="page"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <span className="text-info fw-bolder ms-2" id="welcome"></span>
      {/* <h5 className="text-info fw-bold ms-2">
        Total visitor{" "}
        <span className="text-danger fw-bolder"> {visitors} </span>
      </h5> */}
      <div>
        <PageRoutes />
      </div>
      <div
        className="col-md-12 text-center bg-white"
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        Copyright &copy;{" "}
        <span className="text-success fw-bold">{new Date().getFullYear()}</span>{" "}
        Website by{" "}
        <Link style={{ fontSize: "15px" }} href="https://www.mwerezi.co.ke">
          MWEREZI INTERNATIONAL
        </Link>{" "}
        All rights reserved.Version 1.0
      </div>
    </div>
  );
}

export default App;

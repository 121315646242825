import { getLocation } from "./Data";
import AccessLogs from "./AccessLogs";

export const userDetails = async () => {
  getLocation()
    .then((data) => {
      if (data) {
        const frmData = new FormData();
        frmData.append("user_id", localStorage.getItem("id_mwerezi"));
        frmData.append("ip", data.ip);
        frmData.append("city", data.city);
        frmData.append("country_name", data.country_name);
        frmData.append("region_code", data.region_code);
        frmData.append("lat", data.latitude);
        frmData.append("lon", data.longitude);
        frmData.append("currency", data.currency);
        frmData.append("currency_name", data.currency_name);
        frmData.append("country_area", data.country_area);
        frmData.append("country_population", data.country_population);
        frmData.append("org", data.org);

        AccessLogs(frmData);
      }
    })
    .catch(error=>console.log('Error accessing'));
};

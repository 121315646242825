import React, { useState, useEffect } from "react";
import { postData } from "../components/helpers/Data";
import Modal from "../components/helpers/Modal";

const WebsitePackages = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const email = localStorage.getItem("email_mwerezi");

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const orderPackage = (package_name) => {
    if (email) {
      const frmData = new FormData();
      frmData.append("package", package_name);
      frmData.append("type", "website");
      frmData.append("id", localStorage.getItem("id_mwerezi"));

      postData(frmData, "order_packages")
        .then((data) => {
          if (data[0].includes("Error")) {
            setResponse("Error ordering gold package ");
          } else {
            setResponse(data);
          }
          setIsModalOpen(true);
        })
        .catch((error) => {
          setResponse("Error ordering gold package!");
          setIsModalOpen(true);
        });
    } else {
      setResponse("Login first to order!");
      setIsModalOpen(true);
    }
  };
  return (
    <div
      className="mb-5 d-grid gap-3 text-center"
      style={{ marginTop: "3px" }}
      id="packageDiv"
    >
      <div className="row p-2 ">
        <h3
          style={{
            fontSize: "40px",
            color: "purple",
            fontWeight: "bolder",
          }}
        >
          Website design
        </h3>
        <hr />
        <p
          style={{
            // fontFamily: "Ephesis, cursive",
            fontSize: "20px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          They say CHEAP IS EXPENSIVE. The truth is cheap is always expensive.
          With cheap the quality of a product is always lowered. Our website
          packages are not CHEAP, they are AFFORDABLE! Whether you are
          interested in the lowest or highest package, we strive to maintain the
          highest quality as we make them pocket friendly. We offer multiple
          website designs to catter for your needs . Find yours today!
        </p>
        <div className="col-md-12 ">
          <div className="row justify-content-center">
            <div className="col-md-3 mb-5">
              <div
                className="card px-0"
                style={{
                  boxShadow: "5px 10px 8px #888888",
                }}
              >
                <div className="card-header bg-dark text-warning fw-bolder h1">
                  Bronze
                  <p className="h5">15,999 Kshs</p>
                </div>
                <div className="card-body">
                  <p>Best for small businesses with minimum web traffic</p>
                  <hr />
                  <p>Beautiful responsive web Designs</p>
                  <hr />
                  <p>1 website design to choose from</p>
                  <hr />
                  <p>unlimited business emails</p>
                  <hr />
                  <p>1 free .co.ke domain for 1 year</p>
                  <hr />
                  <p>free basic hosting for 1 year</p>
                  <hr />
                  <p>1 to 20 pages</p>
                  <hr />
                  <p>Social media integration (facebook)</p>
                  <hr />
                  <button
                    class="btn btn-success"
                    onClick={() => orderPackage("bronze")}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div
                className="card px-0"
                style={{
                  boxShadow: "5px 10px 8px #888888",
                }}
              >
                <div className="card-header bg-dark text-danger fw-bolder h1">
                  Silver
                  <p className="h5">30,999 Kshs</p>
                </div>
                <div className="card-body">
                  <p>Best for medium businesses with medium web traffic</p>
                  <hr />
                  <p>Beautiful responsive web Designs</p>
                  <hr />
                  <p>3 website designs to choose from</p>
                  <hr />
                  <p>unlimited business emails</p>
                  <hr />
                  <p>1 free (.co.ke, .com) domain for 1 year</p>
                  <hr />
                  <p>free standard hosting for 1 year</p>
                  <hr />
                  <p>21 to 40 pages</p>
                  <hr />
                  <p>
                    Social media integration (Facebook, Twitter, and Instagram)
                  </p>
                  <hr />
                  <button
                    class="btn btn-success"
                    onClick={() => orderPackage("silver")}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div
                className="card px-0"
                style={{
                  boxShadow: "5px 10px 8px #888888",
                }}
              >
                <div className="card-header bg-dark text-info fw-bolder h1">
                  Gold
                  <p className="h5">60,999 Kshs</p>
                </div>
                <div className="card-body">
                  <p>
                    Best for medium and large businesses with high web traffic
                  </p>
                  <hr />
                  <p>Beautiful responsive web Designs</p>
                  <hr />
                  <p>6 website designs to choose from</p>
                  <hr />
                  <p>unlimited business emails</p>
                  <hr />
                  <p>1 free (.co.ke, .com, .net) domain for 1 year</p>
                  <hr />
                  <p>free premium hosting for 1 year</p>
                  <hr />
                  <p>41 to 60 pages</p>
                  <hr />
                  <p>
                    Social media integration (Facebook, Twitter, Instagram, and
                    LinkedIn)
                  </p>
                  <hr />
                  <p>Payment integration (mpesa)</p>
                  <hr />
                  <button
                    class="btn btn-success"
                    onClick={() => orderPackage("gold")}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div
                className="card px-0"
                style={{
                  boxShadow: "5px 10px 8px #888888",
                }}
              >
                <div
                  className="card-header bg-dark fw-bolder h1"
                  style={{ color: "#2ECC71" }}
                >
                  Platinum
                  <p className="h5">Contact us for price</p>
                </div>
                <div className="card-body">
                  <p>
                    Best for medium and large businesses with high web traffic
                  </p>
                  <hr />
                  <p>Beautiful responsive web Designs</p>
                  <hr />
                  <p>10 website designs to choose from</p>
                  <hr />
                  <p>unlimited business emails</p>
                  <hr />
                  <p>1 free (.co.ke, .com, .net, .ke) domain for 1 year</p>
                  <hr />
                  <p>free premium hosting for 1 year</p>
                  <hr />
                  <p>61 and above pages</p>
                  <hr />
                  <p>
                    Social media integration (Facebook, Twitter, Instagram,
                    LinkedIn, and Whatsapp)
                  </p>
                  <hr />
                  <p>Payment Integration (Mpesa, Paypal, bank cards)</p>
                  <hr />
                  <p>Other integrations (Maps, Analytics, geolocations)</p>
                  <hr />
                  <button
                    class="btn btn-success"
                    onClick={() => orderPackage("platinum")}
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalIsOpen={isModalOpen}
        closeModal={closeModal}
        body={<p className="text-white fw-bolder">{response}</p>}
        button={
          <p>
            <button onClick={closeModal}></button>
          </p>
        }
      />
    </div>
  );
};

export default WebsitePackages;

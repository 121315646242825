import React, { useEffect } from "react";
import "../css/aboutus.css";

const AboutUs = () => {
  return (
    <div className="container mb-5">
      <div className="text-center" style={{ margin: "10px" }}>
        <img
          src="imgs/growth.jpg"
          width="60%"
          style={{ pointerEvents: "none" }}
        />
        <p className="text-info h3">Mission</p>
        <hr />
        <p className="text-secondary h5" >
          Our mission is to empower and offer technology business solutions to
          all types of companies i.e large, medium, and small enterprises and
          enable them to achieve their emediate and future goals. Our business
          solution bridges the gap between companies and their employees and
          also between companies and their clients or customers. We achieve this
          by building technology platforms such as website and mobile
          applications that are friendly, easy to use, advanced, beautiful, and
          modern.
        </p>
        <p className="text-info h3">Vision</p>
        <hr />
        <p className="text-secondary h5">
          Our vision is to empower businesses in the entire continent of africa
          with revolutionary technology business solutions so that they can rise
          to a level where they can compete with multinational large
          enterprises.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;

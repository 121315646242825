import React, { useEffect } from "react";
import PortfolioCard from "../components/helpers/PortfolioCard";

const Portfolio = () => {
  return (
    <div className="container-fluid mb-5">
      {/* <h3
        className="text-center"
        style={{
          fontSize: "40px",
          color: "purple",
          fontWeight: "bolder",
        }}
      >
        PORTFOLIO
      </h3>
      <hr /> */}
      <div className="row m-2 justify-content-center">
        <div className="col-md-12 text-center mb-4 ">
          <p className="h1 text-success text-center fw-bolder" style={{fontFamily:"Brush Script MT', cursive"}}> Point Of Sale system</p>
          <p className="text text-center">We have a state of the art point of sale system that caters for all types of businesses. If your goal is to have a fast and easy to use system with reporting capability and other features such as mpesa integrations, then our system is the best for your business.</p>
          <video
            className="col-md-12"
            id="myVideo"
            autoPlay
            muted
            loop
            >
            <source src="/videos/mpos.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="col-md-12 text-center">
        <p className="h1 text-success text-center mb-4 fw-bolder" style={{fontFamily:"Brush Script MT', cursive"}}> Pharmacy Management System</p>
          <p className="text text-center">Our pharmacy system is tailor made to handle all pharmacy transactions and also include patient diagnosis and prescription functionalities as well as reporting capabilities.</p>
          <video
            className="col-md-12"
            id="myVideo"
            autoPlay
            muted
            loop
          >
            <source src="/videos/mpharma.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {/* <button onclick="playVideo()">Play</button>
        <button onclick="pauseVideo()">Pause</button> */}
      </div>
      {/* <div className="row">
        <PortfolioCard
          src="https://play-lh.googleusercontent.com/cSSxqsWKui897XjX3PwFXYWidalYsZv5WSRoo3ck9oryzdAZjxNHwFoolZ0wsXJwFSfT=s180-rw"
          header="Happy Notepad"
          body=" This is a note taking tool that allows users to write beautiful
            notes with different font styles and colors. It is smooth and
            userfriendly to use."
          link="https://play.google.com/store/apps/details?id=com.mwerezi.happynotepad"
        />
        <PortfolioCard
          src="https://play-lh.googleusercontent.com/4JwB-8Ulnw6Ho6SaE0bXFPXpPfV3xd-XNTYGdRgSvDgPzP4M7_TBKG1RyjyVbpMem4U=s180-rw"
          header="Africa Sumwi Designs"
          body=" This is a fashion design application for children, men, and women. It offers a blend between african and modern designs."
          link="https://play.google.com/store/apps/details?id=com.mwerezi.africasumwidesigns"
        />
        <PortfolioCard
          src="imgs/totalhealth.png"
          header="Mwerezi Total Health"
          body="This is a website for mwerezi total health. It offers a wide variety of natural health suppliments for healthier growth"
          link="https://totalhealth.mwerezi.co.ke"
        />
        <PortfolioCard
          src="imgs/fashion.png"
          header="Mwerezi Fashion"
          body="This is a website for mwerezi fashion. It offers a wide variety fashion designs for children, men, and women."
          link="https://fashion.mwerezi.co.ke"
        />
      </div> */}
    </div>
  );
};

export default Portfolio;

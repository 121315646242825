import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { postData } from "../components/helpers/Data";
import Modal from "../components/helpers/Modal";
import { userDetails } from "../components/helpers/UserDetails";

const Login = () => {
  //save access logs
  useEffect(() => {
    userDetails();
  }, []);

  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const loginUser = (e) => {
    e.preventDefault();
    const frmData = new FormData(document.getElementById("login_frm"));
    postData(frmData, "login_user")
      .then((data) => {
        if (data.length !== 0) {
          localStorage.setItem("id_mwerezi", data[0].id);
          localStorage.setItem("email_mwerezi", data[0].email);
          localStorage.setItem("username_mwerezi", data[0].username);
          localStorage.setItem("phone_mwerezi", data[0].phone);
          setResponse(`Welcome ${data[0].username}`);
          setIsModalOpen(true);
        } else {
          setResponse("Email or password is not correct!");
          setIsModalOpen(true);
        }
      })
      .catch((error) => {
        setResponse("Error Login in!");
        setIsModalOpen(true);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (response.includes("Welcome")) {
      history.push("/");
    }
  };
  return (
    <div className="container-fluid mb-5">
      <div className="row justify-content-center">
        <div className="col-md-6" style={{ marginTop: "10%" }}>
          <form
            id="login_frm"
            onSubmit={loginUser}
            className="form-control d-grid gap-5  mt-10 bg-light"
            style={{ boxShadow: "5px 10px 8px #888888" }}
          >
            <input
              type="email"
              name="email"
              id="email"
              className="form-control mt-4"
              placeholder="Email"
              required="true"
            />
            <input
              type="password"
              name="password"
              id="password"
              className="form-control"
              placeholder="Password"
              required="true"
            />
            <input
              type="submit"
              value="Login"
              className="btn btn-info text-white fw-bolder mb-4"
            />
          </form>
          <p className="text-center mt-5">
            You don't have an account?{" "}
            <button
              className="btn btn-success"
              onClick={() => history.push("/create-account")}
            >
              Create account
            </button>
          </p>
        </div>
        <Modal
          modalIsOpen={isModalOpen}
          closeModal={closeModal}
          body={<h2 className="text-white fw-bolder">{response}</h2>}
          button={
            <p>
              <button onClick={closeModal}></button>
            </p>
          }
        />
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import { Switch, Route } from "react-router-dom";

import "../../css/index.css";

import Home from "../../../src/pages/Home";
import WebsitePackages from "../../pages/WebsitePackages";
import Portfolio from "../../../src/pages/Portfolio";
import ContactUs from "../../../src/pages/ContactUs";
import AboutUs from "../../../src/pages/AboutUs";
import MobilePackages from "../../pages/MobilePackages";
import Login from "../../pages/Login";
import CreateAccount from "../../pages/CreateAccount";
import Profile from "../../pages/Profile";
import Orders from "../../pages/Orders";

const PageRoutes = () => {
  return (
    <div className="m-0">
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/web-packages">
          <WebsitePackages />
        </Route>
        <Route path="/mobile-packages">
          <MobilePackages />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/aboutus">
          <AboutUs />
        </Route>
        <Route path="/contactus">
          <ContactUs />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/create-account">
          <CreateAccount />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/orders">
          <Orders />
        </Route>
      </Switch>
    </div>
  );
};

export default PageRoutes;

import React from "react";
import { useHistory } from "react-router-dom";

const LoginFirstToProceed = (props) => {
   const history = useHistory();
  return (
    <div className="d-flex justify-content-center">
      <div
        className="text-center h2 text-danger fw-bolder card bg-white p-5"
        style={{ marginTop: "20%" }}
      >
        <p>{props.message}</p>
        <button
          className="btn btn-success mt-5 fw-bolder"
          onClick={() => history.push("/login")}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginFirstToProceed;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { postData } from "../components/helpers/Data";
import Modal from "../components/helpers/Modal";

const CreateAccount = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const createAccount = (e) => {
    e.preventDefault();
    const frmData = new FormData(document.getElementById("create_account_frm"));
    const password = document.getElementById("password").value;
    const confirm_password = document.getElementById("confirm_password").value;
    if (password === confirm_password) {
      if (password.length >= 8) {
        postData(frmData, "create_user")
          .then((data) => {
            setResponse(data);
            setIsModalOpen(true);
          })
          .catch((error) => {
            setResponse("Error when creating user!");
            setIsModalOpen(true);
          });
      } else {
        setResponse("Password must be 8 characters or more!");
        setIsModalOpen(true);
      }
    } else {
      setResponse("Password does not match!");
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (response[0].includes("User created successfully")) {
      history.push("/");
    }
  };

  return (
    <div className="container-fluid mb-5">
      <div className="row justify-content-center">
        <div className="col-md-6" style={{ marginTop: "10%" }}>
          <form
            onSubmit={createAccount}
            id="create_account_frm"
            className="form-control d-grid gap-3  mt-10 bg-light"
            style={{ boxShadow: "5px 10px 8px #888888" }}
          >
            <input
              type="email"
              name="email"
              id="email"
              className="form-control mt-4"
              placeholder="Email"
              required="true"
            />
            <input
              type="text"
              name="username"
              id="username"
              className="form-control mt-4"
              placeholder="Username"
              required="true"
            />
            <input
              type="text"
              name="phone"
              id="phone"
              className="form-control mt-4"
              placeholder="Phone No"
              required="true"
            />
            <input
              type="password"
              name="password"
              id="password"
              className="form-control mt-4"
              placeholder="Password"
              required="true"
            />
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              className="form-control mt-4"
              placeholder="Confirm Password"
              required="true"
            />
            <input
              type="submit"
              className="btn btn-info text-white fw-bolder mt-4 mb-3"
              value="Create Account"
            />            
          </form>
        </div>

        <Modal
          modalIsOpen={isModalOpen}
          closeModal={closeModal}
          body={<p className="text-white fw-bolder">{response}</p>}
          button={
            <p>
              <button onClick={closeModal}></button>
            </p>
          }
        />
      </div>
    </div>
  );
};

export default CreateAccount;

import React, { useState, useEffect } from "react";
import { postData } from "../components/helpers/Data";
import Modal from "../components/helpers/Modal";

const ContactUs = () => { 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const id = localStorage.getItem("id_mwerezi");
  const username = localStorage.getItem("username_mwerezi");
  const email = localStorage.getItem("email_mwerezi");

  const sendMessage = (e) => {
    e.preventDefault();
    if (email) {
      const message = document.getElementById("message").value;
      const frmData = new FormData();
      frmData.append("id", id);
      frmData.append("email", email);
      frmData.append("username", username);
      frmData.append("message", message);

      postData(frmData, "save_contact")
        .then((data) => {
          if (data[0].includes("Error")) {
            setResponse("Error sending message!");
          } else {
            setResponse(data[0]);
            document.getElementById("message").value = "";
          }
          setIsModalOpen(true);
        })
        .catch((error) => {
          setResponse("Error Login in!");
          setIsModalOpen(true);
        });
    } else {
      setResponse("You must be logged in to send message!");
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="container-fluid mx-auto mb-5" style={{ marginTop: "50px" }}>
      <form id="frmContacts" onSubmit={sendMessage}>
        <div className="d-grid gap-3 ">
          <input className="form-control" type="text" value={email} readOnly />
          <textarea
            className="form-control"
            id="message"
            cols="30"
            rows="10"
            required="true"
            placeholder="Type your message"
          />
          <input
            className="btn btn-success col-md-12"
            type="submit"
            value="Send Message"
          />
        </div>
      </form>
      <div className="row mt-5 mb-4 text-center fw-bolder h5">
        <div className="col-md-4">
          <i className="fa fal fa-phone-alt text-info  h4"></i> +254742551965
        </div>
        <div className="col-md-4">
          <i className="fab fa-whatsapp text-success h4"></i> +254742551965
        </div>
        <div className="col-md-4">
          <i className="fas fa-envelope text-danger h4"></i> info@mwerezi.co.ke
        </div>
      </div>
      <Modal
        modalIsOpen={isModalOpen}
        closeModal={closeModal}
        body={<h2 className="text-white fw-bolder">{response}</h2>}
        button={
          <p>
            <button onClick={closeModal}></button>
          </p>
        }
      />
    </div>
  );
};

export default ContactUs;

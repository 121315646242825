import { postData } from "./Data";

const AccessLogs = (frmData) => {
  postData(frmData, "save_access_logs")
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export default AccessLogs;

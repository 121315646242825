import React, { useState, useEffect } from "react";
import { postData } from "../components/helpers/Data";
import LoginFirstToProceed from "../components/helpers/LoginFirstToProceed";
import { useHistory } from "react-router-dom";
import Modal from "../components/helpers/Modal";

const Profile = () => {
  const history = useHistory();
  const email = localStorage.getItem("email_mwerezi");
  const phone = localStorage.getItem("phone_mwerezi");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  
  const changePassword = (e) => {
    e.preventDefault();
    const new_password = document.getElementById('new_password').value;
    const confirm_password = document.getElementById('confirm_password').value;
    if (new_password === confirm_password) {
    
      const frmData = new FormData(document.getElementById("frmChangePass"));
      frmData.append("username", localStorage.getItem("username_mwerezi"));
      frmData.append("email", localStorage.getItem("email_mwerezi"));
      postData(frmData, "change_password")
        .then((data) => {
          if (data[0].includes("Error")) {
            setResponse("Error changing password!");
            setIsModalOpen(true);
          } else {
            setResponse(data[0]);
            setIsModalOpen(true);
          }
        })
        .catch((error) => {
          setResponse("Error changing password!");
          setIsModalOpen(true);
        });

    } else {
      setResponse("Password does not match!");
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);   
  };
  return (
    <div className="container">
      {email && phone ? (
        <div className="row">
          <div className="col-md-6">
            <p className="text-center text-info fw-bolder h2">Profile</p>
            <hr />
            <p className="text-center fw-bold text-success h4">
              {localStorage.getItem("username_mwerezi")}
            </p>
            <p className="text-center fw-bold text-success h4">
              {localStorage.getItem("phone_mwerezi")}
            </p>
            <p className="text-center fw-bold text-success h4">
              {localStorage.getItem("email_mwerezi")}
            </p>
          </div>
          <div className="col-md-6">
            <p className="text-center text-info fw-bolder h2">
              Change Password
            </p>
            <hr />
            <form
              id="frmChangePass"
              className="d-grid gap-3"
              onSubmit={changePassword}
            >
              <input
                className="form-control"
                type="password"
                name="current_password"
                placeholder="Enter current password"
                required="true"
              />
              <input
                className="form-control"
                type="password"
                id="new_password"
                name="new_password"
                placeholder="Enter New Password "
                required="true"
              />
              <input
                className="form-control"
                type="password"
                id="confirm_password"
                placeholder="Confirm New Password"
                required="true"
              />
              <input
                className="btn btn-success"
                type="submit"
                value="Change Password"
              />
            </form>
          </div>
        </div>
      ) : (
        <LoginFirstToProceed message="Login first to view your profile" />
      )}

      <Modal
        modalIsOpen={isModalOpen}
        closeModal={closeModal}
        body={<h2 className="text-white fw-bolder">{response}</h2>}
        button={
          <p>
            <button onClick={closeModal}></button>
          </p>
        }
      />
    </div>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import { postData } from "../components/helpers/Data";
import LoginFirstToProceed from "../components/helpers/LoginFirstToProceed";

const Orders = () => {
  const [orders, setOders] = useState([]);
  const email = localStorage.getItem("email_mwerezi");
  const phone = localStorage.getItem("phone_mwerezi");

  useEffect(() => {
    const frmData = new FormData();
    frmData.append("user_id", localStorage.getItem("id_mwerezi"));
    postData(frmData, "fetch_orders")
    .then((data) => {
      setOders(data);
    })
    .catch((error) => {
      console.log("Error loading orders");
      });
  }, []);

  return (
    <div className="container mt-5">
      {email && phone ? (
        <table
          className="table table-borderless table-striped"
          style={{ maxHeight: "700px" }}
        >
          <thead className="bg-info text-center text-white fw-bolder h4">
            <tr>
              <th>Order Type</th>
              <th>Package</th>
              <th>Order Date</th>
              <th>Order Status</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {orders.map((dt) => {
              return (
                <tr>
                  <td>{dt.type}</td>
                  <td>{dt.package}</td>
                  <td>{dt.order_date}</td>
                  <td>
                    {dt.order_status == 0 ? (
                      <p className="text-warning fw-bold">Pending</p>
                    ) : dt.order_status == 1 ? (
                      <p className="text-success fw-bold">Accepted</p>
                    ) : (
                      <p className="text-danger fw-bold">Rejected</p>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <LoginFirstToProceed message="Login first to view your orders" />
      )}
    </div>
  );
};

export default Orders;
